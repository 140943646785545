import React from 'react';
import _ from 'lodash';
import {graphql} from 'gatsby';

import {Layout} from '../components/index';
import {htmlToReact} from '../utils';

import Img from 'gatsby-image'

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query {
    allFile(filter: {childImageSharp: {id: {ne: null}}}) {
      nodes {
        relativePath
        childImageSharp {
          fluid (maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

export default class Page extends React.Component {
    render() {
        const getNode = (path) => this.props.data.allFile.nodes.find(node => node.relativePath.endsWith(path))
        const getFilename = (path) => path.split('/').splice(-1)[0]
        return (
            <Layout {...this.props}>
              <article className="post page post-full">
                <header className="post-header inner-md">
                  <h1 className="post-title">{_.get(this.props, 'pageContext.frontmatter.title', null)}</h1>
                  {_.get(this.props, 'pageContext.frontmatter.subtitle', null) && (
                  <div className="post-subtitle">
                    {htmlToReact(_.get(this.props, 'pageContext.frontmatter.subtitle', null))}
                  </div>
                  )}
                </header>
                {_.get(this.props, 'pageContext.frontmatter.cover', null) && (
                <div className="post-thumbnail">
                  <Img fluid={getNode(getFilename(this.props.pageContext.frontmatter.cover)).childImageSharp.fluid} style={{height: 300}}/>
                </div>
                )}
                <div className="post-content inner-md">
                  {htmlToReact(_.get(this.props, 'pageContext.html', null))}
                </div>
              </article>
            </Layout>
        );
    }
}
